import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Login } from '../../models/login';
import { TechnicalSupport } from 'src/app/home/models/AVAModel/AVAResultData';
import { Shared } from 'src/app/demo/V3/services/shared';

@Component({
  selector: 'app-otppage',
  templateUrl: './otppage.component.html',
  styleUrls: ['./otppage.component.css']
})
export class OTPPageComponent implements OnInit {
  public otp: string;
  public storedOTPExpired: string;
  public isVisibleTimer: boolean = false;
  public errorMessage: string = null;
  public timerMinutes: number = 5; // Set initial timer minutes
  public timerSeconds: number = 0; // Set initial timer seconds
  public canResend: boolean = false; // Button enabled/disabled
  public timer: any; // Timer reference
  public timerOn: boolean = false;
  public isResend: boolean = false;
  public otpResend: boolean = false;
  public expirationTime: any;
  public isVisibleVerify: boolean = false;
  public login = new Login(null, null, false, null);

  // focusToFirstElementAfterValueUpdate:boolean=false;
  // @ViewChild('ngOtpInput') ngOtpInputRef:any;

  constructor(private elementRef: ElementRef,
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    console.log(this.otpResend);
    window.addEventListener('popstate', this.handleBackNavigation);
    this.route.queryParams
      .subscribe(params => {
        if (params && params['email'] != null && params['password'] != null && params['termsAndConditions'] != null && params['expirationTime'] != null) {
          if ((this.timerMinutes != 0 || this.timerSeconds != 0) && localStorage.getItem('otpExpirationTime')) {
            this.startTimer();
            this.isVisibleTimer = true;
            if(this.timerOn) {
              this.otpResend = true;
              this.isResend = false;
            }
          } else {
            this.isVisibleTimer = false;
            this.canResend = true;
          }
          this.expirationTime = params['expirationTime'];
          this.login.email = params['email'];
          this.login.password = params['password'] ? atob(params['password']) : null;
          this.login.termsAndConditions = params['termsAndConditions'] == 'true' ? true : false;
        }
      });

    // this.route.queryParams.subscribe(params => {
    //   // Check if the timer has already expired
    //   this.storedOTPExpired = localStorage.getItem('otpExpired');
    //   if (this.storedOTPExpired === 'true') {
    //     // If expired, handle accordingly (e.g., show an error or redirect)
    //     this.isVisibleTimer = false;
    //     this.isResend = true;
    //     // alert('The OTP session has expired. Please request a new OTP.');
    //     return;
    //   } 

    //   if (
    //     params &&
    //     params['email'] != null &&
    //     params['password'] != null &&
    //     params['termsAndConditions'] != null &&
    //     params['expirationTime'] != null
    //   ) {
    //     this.expirationTime = params['expirationTime'];
    //     this.login.email = params['email'];
    //     this.login.password = params['password'] ? atob(params['password']) : null;
    //     this.login.termsAndConditions =
    //       params['termsAndConditions'] == 'true' ? true : false;

    //     // Check if the expiration time is still valid
    //     const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    //     const expirationTimestamp = parseInt(this.expirationTime, 10);

    //     if (expirationTimestamp > currentTime ||  this.storedOTPExpired == 'false') {
    //       this.startTimer(); // Start the timer if valid
    //       this.isVisibleTimer = true;
    //     } else {
    //       // If expired, handle expiration
    //       this.handleTimerExpiration();
    //     }
    //   }
    // });

  }

  ngOnDestroy(): void {
    // Clean up the event listener to avoid memory leaks
    window.removeEventListener('popstate', this.handleBackNavigation);
  }


  handleBackNavigation(): void {
    // Remove specific key(s) from localStorage
    localStorage.removeItem('otpExpirationTime');
  }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/LoginBackground.svg)';
  }

  onOtpChange(event) {
    this.otp = event;
    if (this.otp.length < 6 || this.otp == null) {
      if (this.isResend) {
        this.isResend = false;
      }
      if (!this.timerOn) {
        this.isVisibleVerify = true;
        this.canResend = true;
      } else {
        this.isVisibleTimer = true;
        this.canResend = false;
      }
      this.errorMessage = null;
      this.isVisibleVerify = false;
    }
    else if (this.otp.length == 6) {
      this.canResend = false;
      this.isVisibleVerify = true;
    }
    this.login.oTP = this.otp;
  }

  onKeydown(event: KeyboardEvent): void {
    if (!/^\d$/.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }

  verifyOtp() {
    this.canResend = false
    const currentDateTimeISO: string = new Date().toISOString();
    if (((currentDateTimeISO >= this.expirationTime || (this.isResend && !this.timerOn)) && this.otpResend != true)) {
      this.errorMessage = "Timeout";
      this.canResend = true;
      this.isVisibleVerify = false;
    } else {
      this.errorMessage = null;
      this.loginService.Verify2FA(this.login)
        .subscribe(data => {
          this.errorMessage = null;
          localStorage.setItem("access_token", data["access_token"]);
          localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
          localStorage.setItem("token_type", data["token_type"]);
          localStorage.setItem("userId", data["userId"]);
          localStorage.setItem("username", data["username"]);
          this.router.navigate(["/v3"]);
        }, error => {
          // console.log(error);
          if (error.status == 400) {
            if (this.timerOn) {
              this.canResend = false;
              this.isVisibleTimer = true;
            } else {
              this.isVisibleTimer = false;
            }
            this.errorMessage = 'Invalid OTP';
          }
        })
    }
  }

  // startTimer(): void {
  //   this.timerOn = true;
  //   this.canResend = false;
  //   this.timerMinutes = 5; // Reset minutes
  //   this.timerSeconds = 0; // Reset seconds

  //   this.timer = setInterval(() => {
  //     if (this.timerSeconds === 0) {
  //       if (this.timerMinutes > 0) {
  //         this.timerMinutes--;
  //         this.timerSeconds = 59;
  //       } else {
  //         clearInterval(this.timer);
  //         this.timerOn = false;
  //         if(this.otp !=null && this.otp.length <= 6){
  //           this.canResend = false; 
  //           this.isVisibleVerify = true;
  //         }
  //         this.isVisibleTimer = false;
  //         this.isResend = true;
  //       }
  //     } else {
  //       this.timerSeconds--;
  //     }
  //   }, 1000);
  // }

  startTimer(): void {
    // Clear the expired flag (if previously set)
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const expirationTime = localStorage.getItem('otpExpirationTime');

    if (expirationTime) {
      const expirationTimestamp = parseInt(expirationTime, 10);

      if (expirationTimestamp > currentTime) {
        // Calculate remaining time
        const remainingTime = expirationTimestamp - currentTime;
        this.timerMinutes = Math.floor(remainingTime / 60);
        this.timerSeconds = remainingTime % 60;
      } else {
        // OTP expired
        this.handleTimerExpiration();
        return;
      }
    }

    // else {
    //   // First time or no expiration time found
    //   const newExpirationTime = currentTime + 1 * 60; // 5 minutes from now
    //   localStorage.setItem('otpExpirationTime', newExpirationTime.toString());
    // }

    this.timerOn = true;
    this.canResend = false;

    this.timer = setInterval(() => {
      if (this.timerSeconds === 0) {
        if (this.timerMinutes > 0) {
          this.timerMinutes--;
          this.timerSeconds = 59;
        } else {
          clearInterval(this.timer);
          this.expirationTime = "";
          this.handleTimerExpiration();
        }
      } else {
        this.timerSeconds--;
      }
    }, 1000);
  }

  handleTimerExpiration(): void {
    this.otpResend = false;
    this.timerOn = false;
    this.canResend = true;
    this.isVisibleVerify = false;
    this.isVisibleTimer = false;
    this.isResend = true;

    // Remove the expiration time from storage
    localStorage.removeItem('otpExpirationTime');

    // Optional: Display a message to the user
    // alert('OTP has expired. Please request a new OTP.');
  }


  resendOtp(): void {
    if (this.canResend) {
      this.errorMessage = null;
      this.isVisibleTimer = true;
    }
    this.otpResend = true;
    this.isResend = true;
    this.loginService.Userlogin(this.login)
      .subscribe(
        data => {
          // console.log(data['message']);
          console.log(data['expiresAt']);
          this.expirationTime = data['expiresAt'];
          if (data['message'] != null && data['expiresAt'] != null) {
            console.log('Resending OTP...');
            this.timerMinutes = 5;
            this.timerSeconds = 0;
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            const newExpirationTime = currentTime + 5 * 60; // 5 minutes from now
            localStorage.setItem('otpExpirationTime', newExpirationTime.toString());
            this.startTimer();
          }
        })
  }
}
