import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take, } from "rxjs/operators";
import { SearchService } from "../search.service";
import { Token } from "../../search-pages/models/Items";
import { Shared } from "../shared";

export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(private searchService: SearchService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(
        this.isAuthHeaderNeeded(req.url)
          ? this.addToken(req, localStorage.getItem("access_token"))
          : req
      )
      .pipe(
        catchError((err) => {
          console.log("Error : ", err);
          if (err instanceof HttpErrorResponse) {
            if (err.status == 401) {
              return this.handleRefreshToken(req, next);
            }
          }
          return throwError(err);
        })
      );
  }

  private handleRefreshToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = new Token(localStorage.getItem("access_token"));
      return this.searchService.refreshtoken(refreshToken).pipe(
        switchMap((data: any) => {
          this.isRefreshing = false;
          const newToken = data["access_token"];
          this.refreshTokenSubject.next(newToken);
          localStorage.setItem("access_token", newToken);
          localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
          localStorage.setItem("token_type", data["token_type"]);
          return next.handle(this.addToken(request, newToken));
        }),
        catchError((error) => {
          this.isRefreshing = false;
          if (error.status == 400) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("expires_in");
            localStorage.removeItem("token_type");
            localStorage.removeItem("userId");
            localStorage.removeItem("username");
          }
          return throwError(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

  private addToken(
    request: HttpRequest<any>,
    token: string | null
  ): HttpRequest<any> {
    if (token) {
      // Define the endpoints where you want to override the Content-Type
      const overrideContentTypeEndpoints = [
        '/api/V3Search/DocumentValidation'
      ];

      // Check if the request URL matches any of the overrideContentTypeEndpoints
      const shouldOverrideContentType = overrideContentTypeEndpoints.some(endpoint =>
        request.url.includes(endpoint)
      );

      // Clone the request with Authorization header
      let clonedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });

      // If Content-Type should be overridden, set a new value
      if (!shouldOverrideContentType) {
        clonedRequest = clonedRequest.clone({
          setHeaders: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      }
  
      return clonedRequest;
    }
  }

  private isAuthHeaderNeeded(url: string): boolean {
    var controllerPrefix = ["/V3Search", "/ScreenSearch", "/Subscription", "/V3AustraliaSearch"];
    return controllerPrefix.some((x) => url.toLowerCase().includes(x.toLowerCase()));
  }

}
