import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Login } from '../models/login';
import { Register } from '../models/register';
import { Forgetpassword } from '../models/forgetpassword';
import { ResetPassword } from '../models/resetpassword';
import { ResendLink } from '../models/resendlink';
import { Contact } from '../models/contact';
import { Item } from '../../home/models/AccessData';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public licence: Item[];

  constructor(private http: HttpClient) { }

  Userlogin(login: Login): Observable<Login> {
    return this.http.post<Login>('/api/Account/Login', login);
  }

  Verify2FA(login: Login): Observable<Login> {
    return this.http.post<Login>('/api/Account/Verify2FA', login);
  }

  register(user: Register) {
    return this.http.post('/api/Account/Register', user);
  }

  forgetpassword(forgetpassword: Forgetpassword) {
    return this.http.post('/api/Account/ForgetPassword', forgetpassword);
  }

  resetpassword(resetpassword: ResetPassword) {
    return this.http.post('/api/Account/ResetPassword', resetpassword);
  }

  ResendConfirmationLink(email: ResendLink) {
    return this.http.post('/api/Account/ResendConfirmationLink', email);
  }

  ContactDetails(contact: Contact) {
    return this.http.post('/api/Account/ContactDetails', contact);
  }

  GetLicence(): Observable<Item[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ localStorage.getItem("access_token") } `
      })
    };
    return this.http.get<Item[]>('api/search/GetLicenceItem', httpOptions);
  }

  AuthorizationCheck(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${ localStorage.getItem("access_token") } `
      })
    };
    return this.http.get('api/Account/CheckAdmin', httpOptions);
  }

  isAdminUser(): boolean {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) return false;

    const jwtJSON = JSON.parse(window.atob(accessToken.split('.')[1]));
    const role = jwtJSON["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

    if (typeof role === "string") {
      return role === "Admin" || role === "ServiceAdmin" || role === "Partner";
    } else if (role.length > 0) {
      for (let i = 0; i < role.length; i++) {
        if (role[i] === "Admin" || role[i] === "ServiceAdmin" || role[i] === "Partner") {
          return true;
        }
      }
    }
    return false;
  }
}
