export class LandingPageImage {

  // Init image
  public personalImgSrc: string = "";
  public hutScreenImgSrc: string = "";
  public businessImgSrc: string = "";
  public mobileImgSrc: string = "";
  public emailImgSrc: string = "";
  public motorImgSrc: string = "";
  public professionalImgSrc: string = "";
  public photoAnalysisImgSrc: string = "";
  public documentValidationImgSrc: string = "";
  public rtwImgSrc: string = "";
  public idCheckImgSrc: string = "";

  // Disabled grey image
  public personalGreyImgSrc: string = "";
  public hutScreenGreyImgSrc: string = "";
  public businessGreyImgSrc: string = "";
  public mobileGreyImgSrc: string = "";
  public emailGreyImgSrc: string = "";
  public motorGreyImgSrc: string = "";
  public professionalGreyImgSrc: string = "";
  public photoAnalysisGreyImgSrc: string = "";
  public documentValidationGreyImgSrc: string = "";
  public rtwGreyImgSrc: string = "";
  public idCheckGreyImgSrc: string = "";

  // Hover image
  public personalROImgSrc: string = "";
  public hutScreenROImgSrc: string = "";
  public businessROImgSrc: string = "";
  public mobileROImgSrc: string = "";
  public emailROImgSrc: string = "";
  public motorROImgSrc: string = "";
  public professionalROImgSrc: string = "";
  public photoAnalysisROImgSrc: string = "";
  public documentValidationROImgSrc: string = "";
  public rtwROImgSrc: string = "";
  public idCheckROImgSrc: string = "";

  constructor() {
    // Init Image
    this.personalImgSrc = '../../../../assets/Images/new/HUT-icon.v3-personal.png';
    this.hutScreenImgSrc = '../../../../assets/Images/new/HUT-icon.v3-hut-screen.png';
    this.businessImgSrc = '../../../../assets/Images/new/HUT-icon.v3-business.png';
    this.mobileImgSrc = '../../../../assets/Images/new/HUT-icon.v3-mobile.png';
    this.emailImgSrc = '../../../../assets/Images/new/HUT-icon.v3-email.png';
    this.motorImgSrc = '../../../../assets/Images/new/HUT-icon.v3-motor.png';
    this.professionalImgSrc = '../../../../assets/Images/new/HUT-icon.v3-check-profess.png';
    this.photoAnalysisImgSrc = '../../../../assets/Images/new/HUT-icon.v3-photo-analysis.png';
    this.documentValidationImgSrc = '../../../../assets/Images/new/HUT-icon.v3-doc-validation.png';
    this.rtwImgSrc = '../../../../assets/Images/new/HUT-icon.v3-right-to-work.png';
    this.idCheckImgSrc = '../../../../assets/Images/new/HUT-icon.v3-id-check.png';

    // Hover Image
    this.personalROImgSrc = '../../../../assets/Images/new/HUT-icon.v3-personal-RO.png';
    this.hutScreenROImgSrc = '../../../../assets/Images/new/HUT-icon.v3-hut-screen-RO.png';
    this.businessROImgSrc = '../../../../assets/Images/new/HUT-icon.v3-business-RO.png';
    this.mobileROImgSrc = '../../../../assets/Images/new/HUT-icon.v3-mobile-RO.png';
    this.emailROImgSrc = '../../../../assets/Images/new/HUT-icon.v3-email-RO.png';
    this.motorROImgSrc = '../../../../assets/Images/new/HUT-icon.v3-motor-RO.png';
    this.professionalROImgSrc = '../../../../assets/Images/new/HUT-icon.v3-check-profess-RO.png';
    this.photoAnalysisROImgSrc = '../../../../assets/Images/new/HUT-icon.v3-photo-analysis-RO.png';
    this.documentValidationROImgSrc = '../../../../assets/Images/new/HUT-icon.v3-doc-validation-RO.png';
    this.rtwROImgSrc = '../../../../assets/Images/new/HUT-icon.v3-right-to-work-RO.png';
    this.idCheckROImgSrc = '../../../../assets/Images/new/HUT-icon.v3-id-check-RO.png';

    // Grey Image
    this.personalGreyImgSrc = '../../../../assets/Images/new/HUT-icon.v3-personal-grey.png';
    this.hutScreenGreyImgSrc = '../../../../assets/Images/new/HUT-icon.v3-hut-screen-grey.png';
    this.businessGreyImgSrc = '../../../../assets/Images/new/HUT-icon.v3-business-grey.png';
    this.mobileGreyImgSrc = '../../../../assets/Images/new/HUT-icon.v3-mobile-grey.png';
    this.emailGreyImgSrc = '../../../../assets/Images/new/HUT-icon.v3-email-grey.png';
    this.motorGreyImgSrc = '../../../../assets/Images/new/HUT-icon.v3-motor-grey.png';
    this.professionalGreyImgSrc = '../../../../assets/Images/new/HUT-icon.v3-check-profess-GREY.png';
    this.photoAnalysisGreyImgSrc = '../../../../assets/Images/new/HUT-icon.v3-photo-analysis-grey.png';
    this.documentValidationGreyImgSrc = '../../../../assets/Images/new/HUT-icon.v3-doc-validation-grey.png';
    this.rtwGreyImgSrc = '../../../../assets/Images/new/HUT-icon.v3-right-to-work-grey.png';
    this.idCheckGreyImgSrc = '../../../../assets/Images/new/HUT-icon.v3-id-check-grey.png';
  }

}
