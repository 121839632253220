<div class="container">
  <div class="logo-div d-flex justify-content-center">
    <img class="logo" src="assets/Images/new/HUT-icon.v3-personal-RO.png" />
  </div>
  <form role="form">
    <div class="form-group row form-top-margin">
      <div class="col-md-9 form-data-background form-borderdesign  shadow-lg p-3">
        <div class="row padding-form-design">
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Reference:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.referenceNumber}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Name:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.fullName}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Post Code:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.postalCode}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Mobile:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.phoneNumber}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Email:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="email" placeholder="" class="form-control" value="{{requestData.profileData.email}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>DOB:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control datepicker" value="{{requestData.profileData.dateOfBirth}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>1st Line Address:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.addressLine1}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>Association Entities:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="row">
                    <div class="col-md-6">
                      <div>
                        <div class="form-group">
                          <input type="text" placeholder="" class="form-control" value={{requestData?.profileData?.associatedNames[0]?.name}} autocomplete="off" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div>
                        <div class="form-group">
                          <input type="text" placeholder="" class="form-control" value={{requestData?.profileData?.associatedNames[1]?.name}} autocomplete="off" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div>
                        <div class="form-group">
                          <input type="text" placeholder="" class="form-control" value={{requestData?.profileData?.associatedNames[2]?.name}} autocomplete="off" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div>
                        <div class="form-group">
                          <input type="text" placeholder="" class="form-control" value={{requestData?.profileData?.associatedNames[3]?.name}} autocomplete="off" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 z-index-formdata">
            <div class="row">
              <div class="input-group">
                <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                  <b>VRN:</b>
                </div>
                <div class="col-md-8 pt-3">
                  <div class="form-group">
                    <input type="text" placeholder="" class="form-control" value="{{requestData.profileData.vRN}}" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 toggle-div form-borderdesign result-form-background shadow-lg p-3 #F7F7F7">
        <ul class="ul-toggle">
          <li class="result-animation" [ngClass]="requestData?.profileData?.fullName && requestData?.profileData?.postalCode && requestData?.profileData?.addressLine1 ? 'result-get-list-font-design': 'checkmark-default-design'">
            <label class="switch">
              <input type="checkbox" [checked]="requestData.accessData.isLandRegistryAccess">
              <span class="slider round"></span>
            </label>
            <img src="assets/Images/icons/Identity.svg" class="icon-size" />
            <span>Land Registry</span>
          </li>
          <li class="result-animation" [ngClass]="requestData?.profileData?.fullName && requestData?.profileData?.postalCode ? 'result-get-list-font-design': 'checkmark-default-design'">
            <label class="switch">
              <input type="checkbox" [checked]="requestData.accessData.isFinancialAccess">
              <span class="slider round"></span>
            </label>
            <img src="assets/Images/icons/Financial.svg" class="icon-size" />
            <span>Financial</span>
          </li>
          <li class="result-animation" [ngClass]="requestData?.profileData?.fullName ? 'result-get-list-font-design': 'checkmark-default-design'">
            <label class="switch">
              <input type="checkbox" [checked]="requestData.accessData.isQuickSearchEnabled">
              <span class="slider round"></span>
            </label>
            <img src="assets/Images/icons/Financial.svg" class="icon-size" />
            <span><span>Quick Search</span><span class="exclude-criminality">(excludes criminality)</span></span>
          </li>
          <li class="result-animation" [ngClass]="requestData?.profileData?.vRN ? 'result-get-list-font-design': 'checkmark-default-design'">
            <label class="switch">
              <input type="checkbox" [checked]="requestData.accessData.isAVAAccess">
              <span class="slider round"></span>
            </label>
            <img src="assets/Images/icons/AVA.svg" class="icon-size" />
            <span>AVA</span>
          </li>
        </ul>
      </div>
    </div>
  </form>
</div>
