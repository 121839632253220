<div class="card-body">
  <div>
    <span class="card-title Header-Font-Format d-flex justify-content-center mb-3"><b>Mobile</b></span>
    <div>
      <div class="row mb-3">
        <div class="col-md-6 p-0 text-right">
          Risk
        </div>
        <div class="col-md-6 p-0 text-left">
          <div>
            <span class="loadingImg" *ngIf="mobile.isLoading">
              <img src="{{messageService.loadingAnimation}}" />
            </span>

            <div *ngIf="!mobile.isLoading">
              <div class="col-md-12" *ngIf="mobile.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
              <div class="col-md-12" *ngIf="mobile.searchFail"><i class="color-grey float-right">{{messageService.SearchFail}}</i></div>
              <div class="col-md-12" *ngIf="mobile.validationError"><i class="color-grey float-right">{{messageService.MobileRequiredMessage}}</i></div>
            </div>

            <div *ngIf="!mobile.isLoading">
              <div *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk">
                <span *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk?.level == 'low'" class="box box-green ml-3">{{mobile?.responseData?.data?.phoneScoreCheck?.risk?.level}}</span>
                <span *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk?.level == 'very-low'" class="box box-green ml-3">{{mobile?.responseData?.data?.phoneScoreCheck?.risk?.level}}</span>
                <span *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk?.level == 'medium-low'" class="box box-orange ml-3">{{mobile?.responseData?.data?.phoneScoreCheck?.risk?.level}}</span>
                <span *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk?.level == 'high'" class="box box-red ml-3">{{mobile?.responseData?.data?.phoneScoreCheck?.risk?.level}}</span>
                <span *ngIf="mobile?.responseData?.data?.phoneScoreCheck?.risk?.level == 'very-high'" class="box box-red ml-3">{{mobile?.responseData?.data?.phoneScoreCheck?.risk?.level}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 p-0 text-right">
          Match
        </div>
        <div class="col-md-6 p-0 text-left">
          <div>
            <div class="loadingImg" *ngIf="financial.isMobileNumberProvided && financial.isLoading">
              <img src="{{messageService.loadingAnimation}}" />
            </div>

            <div *ngIf="!financial.isLoading">
              <div class="col-md-12" *ngIf="financial.noSearchSelect"><i class="color-grey float-right">{{messageService.NoSearchSelectShort}}</i></div>
              <div class="col-md-12" *ngIf="!financial.noSearchSelect && !financial.validation && !financial.isMobileNumberProvided"><i class="color-grey float-right">{{messageService.MobileRequiredMessage}}</i></div>
              <div class="col-md-12" *ngIf="!financial.noSearchSelect && financial.validation && financial.isMobileNumberProvided"><i class="color-grey float-right">{{messageService.PostCodeRequiredMessage}}</i></div>
              <div class="col-md-12" *ngIf="!financial.noSearchSelect && !financial.searchFail && (!financial?.responseData?.data?.MobileNumbers || financial?.responseData?.data?.MobileNumbers.length == 0)"><i class="color-grey float-right">{{messageService.NoRelevantDataMessageShort}}</i></div>
            </div>

            <div *ngIf="financial.isMobileNumberProvided && !financial.isLoading && !financial.searchFail && financial.responseData && (financial?.responseData?.data?.MobileNumbers && financial?.responseData?.data?.MobileNumbers.length > 0)">
              <span *ngIf="financial?.responseData?.data?.IsMobileFound == true" class="box box-green ml-3">YES</span>
              <span *ngIf="financial?.responseData?.data?.IsMobileFound == false" class="box box-red ml-3">NO</span>
            </div>
            <div *ngIf="financial.isMobileNumberProvided && !financial.isLoading && !financial.searchFail && financial.responseData && (!financial?.responseData?.data?.MobileNumbers || financial?.responseData?.data?.MobileNumbers.length == 0)">
              <span class="ml-3 color-grey">No Data</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
