<div class="container">
  <span>HeadsUp Username: {{requestData.profileData.searchUsername ? requestData.profileData.searchUsername : '-'}}</span>
  <br />
  <span>Search Date &amp; Time: {{requestData.profileData.searchDate}} - {{requestData.profileData.searchTime}}</span>
</div>
<div>
  <app-pdf-search-form-personal [requestData]="requestData"></app-pdf-search-form-personal>
</div>
<br />
<br />
<div class="container">
  <div class="row mb-3" id="new-dashboard">
    <div class="col-md-3 card">
      <app-counterfraud-new-result-personal [counterFraud]="counterFraud" [financial]="financial" [mobile]="mobile"></app-counterfraud-new-result-personal>
    </div>
    <div class="col-md-3 card">
      <app-financial-new-result-personal [financial]="financial"></app-financial-new-result-personal>
    </div>
    <div class="col-md-3 card">
      <app-email-new-result-personal [emailage]="emailage" [financial]="financial"></app-email-new-result-personal>
    </div>
    <div class="col-md-3 card">
      <app-mobile-new-result-personal [mobile]="mobile" [financial]="financial" [requestData]="requestData"></app-mobile-new-result-personal>
    </div>
  </div>
</div>

<div *ngIf="address?.responseData && address?.responseData?.data?.CompleteResponse?.Response && address?.responseData?.data?.CompleteResponse?.Response?.People?.length > 0">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Address</u></b></h3>
  <app-address-result-personal [address]="address"></app-address-result-personal>
  <app-t2a-result-personal [t2a]="t2a"></app-t2a-result-personal>
</div>

<div *ngIf="landRegistry?.responseData?.prsDetails && (landRegistry?.responseData?.prsDetails?.matches?.length > 0 || landRegistry?.responseData?.prsDetails.matchResult)">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Land Registry</u></b></h3>
  <app-landregistry-result-personal [landRegistry]="landRegistry"></app-landregistry-result-personal>
</div>

<div *ngIf="aml?.responseData?.data?.sanctions && !(aml?.responseData?.data?.sanctions == null || aml?.responseData?.data?.sanctions?.length == 0)">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>AML</u></b></h3>
  <app-aml-result-personal [aml]="aml"></app-aml-result-personal>
</div>

<div *ngIf="associated?.responseData && associated?.responseData.length > 0">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Association</u></b></h3>
  <app-association-result-personal [associated]="associated"></app-association-result-personal>
</div>

<div *ngIf="ccj?.responseData && !(ccj?.responseData?.ccjDetails == null || ccj?.responseData?.ccjDetails.length == 0)">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>CCJ</u></b></h3>
  <app-ccj-result-personal [ccj]="ccj"></app-ccj-result-personal>
</div>

<div *ngIf="companiesHouse.responseData">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Companies House</u></b></h3>
  <app-pdf-companieshouse-result-personal [companiesHouse]="companiesHouse"></app-pdf-companieshouse-result-personal>
</div>

<div *ngIf="!criminality.noSearchSelect && criminality.finalResults">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Crime &amp; Legal</u></b></h3>
  <app-pdf-criminality-result-personal [criminality]="criminality"></app-pdf-criminality-result-personal>
</div>

<div *ngIf="emailValidation.responseData || emailage.responseData?.data">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Email</u></b></h3>
  <app-emailvalidation-result-email [emailValidation]="emailValidation"></app-emailvalidation-result-email>
  <br />
  <app-emailage-result-email [emailage]="emailage"></app-emailage-result-email>
</div>

<div *ngIf="insolvency?.responseData && insolvency?.responseData?.insolvencyDetails && insolvency?.responseData?.insolvencyDetails.length > 0">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Insolvency</u></b></h3>
  <app-insolvency-result-personal [insolvency]="insolvency"></app-insolvency-result-personal>
</div>

<div *ngIf="mobile.responseData?.data">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Mobile</u></b></h3>
  <app-mobile-result-mobile [mobile]="mobile"></app-mobile-result-mobile>
</div>

<div *ngIf="opensource.responseData && opensource.responseData.length > 0">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Opensource</u></b></h3>
  <app-opensource-result-mobile [opensource]="opensource"></app-opensource-result-mobile>
</div>

<div *ngIf="!(prospect.prospectHitResponseData?.hits == null || prospect.prospectHitResponseData?.hits.length == 0)">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Prospect</u></b></h3>
  <app-prospect-result-personal [prospect]="prospect"></app-prospect-result-personal>
</div>

<div *ngIf="pipl.responseData || (socialMedia.finalSocialMediaResults && socialMedia.finalSocialMediaResults.length > 0)">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Social Media</u></b></h3>
  <app-pipl-result [pipl]="pipl"></app-pipl-result>
  <br />
  <app-pdf-socialmedia-result-personal [socialMedia]="socialMedia"></app-pdf-socialmedia-result-personal>
</div>

<div *ngIf="sports.finalSportsResults && sports.finalSportsResults.length > 0">
  <div class="page-break"></div>
</div>
<div class="container">
  <h3><b><u>Sports</u></b></h3>
  <app-pdf-sports-result-personal [sports]="sports"></app-pdf-sports-result-personal>
</div>

<div class="container" *ngIf="requestData?.profileData?.vRN">
  <!-- Experian Result -->
  <div *ngIf="isExperianTabShow && experian?.responseData" class="page-break"></div>
  <div *ngIf="isExperianTabShow" class="keep-together">
    <h3><b><u>HUT Motor</u></b></h3>
    <app-pdf-hutmotor-result-motor [experian]="experian"></app-pdf-hutmotor-result-motor>
  </div>
  <!-- DVLA Result -->
  <div class="page-break"></div>
  <div class="keep-together">
    <h3><b><u>DVLA</u></b></h3>
    <app-motor-result-motor [motor]="dvla"></app-motor-result-motor>
  </div>
  <!-- AVA Result -->
  <div *ngIf="ava.responseData" class="page-break"></div>
  <div class="keep-together">
    <h3><b><u>AVA</u></b></h3>
    <app-pdf-ava-result-motor [ava]="ava"></app-pdf-ava-result-motor>
  </div>
  <!-- Prospect Result -->
  <div *ngIf="prospect.isProspectHitAccess && !prospect.searchFail && !prospect.validation && prospect.prospectHitResponseData && !prospect.isProspectHitLoading && !(prospect.prospectHitResponseData?.hits == null || prospect.prospectHitResponseData?.hits.length == 0)" class="page-break"></div>
  <div *ngIf="prospect.isProspectHitAccess" class="keep-together">
    <h3><b><u>Prospect</u></b></h3>
    <app-prospect-result-personal [prospect]="prospect"></app-prospect-result-personal>
  </div>
  <!-- Mot History Result -->
  <div *ngIf="motHistory?.responseData" class="page-break"></div>
  <div class="keep-together">
    <h3><b><u>MOT History</u></b></h3>
    <app-pdf-mothistory-result-motor [motHistory]="motHistory"></app-pdf-mothistory-result-motor>
  </div>
</div>
