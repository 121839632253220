import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from '../shared/guards/auth.guard';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/resetpassword/resetpassword.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { CardModule } from 'primeng/card';
import { OTPPageComponent } from './components/otppage/otppage.component'
import { NgOtpInputModule } from 'ng-otp-input';
//https://alligator.io/angular/providers-shared-modules/

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    NgOtpInputModule,
    RouterModule.forChild([
    ])
  ],
  providers: [
    AuthGuard
  ],
  declarations: [LoginComponent, ForgetPasswordComponent, ResetPasswordComponent, TermsAndConditionsComponent, CookiePolicyComponent, OTPPageComponent]
})
export class SharedModule {
}
