import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from '../../models/login';
import { LoginService } from '../../service/login.service';
import { ResendLink } from '../../models/resendlink';
import { SearchService } from '../../../home/services/search.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute } from '@angular/router';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component'
import { AppComponent } from '../../../app.component';
import { Shared } from 'src/app/demo/V3/services/shared';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService, DialogService]
})

export class LoginComponent implements OnInit {

  public login = new Login(null, null, false, null);
  public accesstoken: string;
  public expirytime: any;
  public tokentype: string;
  public loggedIn: boolean;
  public resendtext: boolean;
  public validationerror: string;
  public resendlink = new ResendLink("");
  public loading: boolean;
  public token: string;
  public tenantId: number;
  public userId: string;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private appComponent: AppComponent
  ) { }

  ngAfterViewInit() {
    this.elementRef.nativeElement.ownerDocument.body.style.background = 'url(/assets/Images/LoginBackground.svg)';
  }

  ngOnInit() {
    setTimeout(() => {
      this.route.queryParams
        .subscribe(params => {
          this.token = params.Token;
          this.userId = params.UserId;
          this.tenantId = params.TenantId;
          if (this.token && this.tenantId && this.userId) {
            this.appComponent.redirectToSettingsWithTenant();
          }
        }
        );
    }, 2000)

    if (this.token) {
      this.appComponent.redirectToSettingsWithTenant();
    }
    else {
      this.loggedIn = localStorage.getItem("access_token") ? true : false;
      if (this.loggedIn) {
        this.router.navigate(["/v3"]);
        this.appComponent.isLoginExpires();
      }
    }
  }

  public Userlogin() {
    this.loading = true;
    this.loginService.Userlogin(this.login)
      .subscribe(
        data => {
          this.loading = false;
          if (data['message'] != null && data['expiresAt'] != null) {
            this.expirytime = data['expiresAt'];
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            const newExpirationTime = currentTime + 5 * 60; // 5 minutes from now
            localStorage.setItem('otpExpirationTime', newExpirationTime.toString());
            this.router.navigate(["/OTPVerification"], { queryParams: { email: this.login.email, password: btoa(this.login.password), termsAndConditions: this.login.termsAndConditions, expirationTime: this.expirytime } });
          } else {
            localStorage.setItem("access_token", data["access_token"]);
            localStorage.setItem("expires_in", Shared.getExpiryDate(Shared.getAccessTokenExpiryDateTime(data["access_token"]).toUTCString()));
            localStorage.setItem("token_type", data["token_type"]);
            localStorage.setItem("userId", data["userId"]);
            localStorage.setItem("username", data["username"]);
            this.router.navigate(["/v3"]);
          }
        },
        error => {
          console.log(error);
          if (error.status == 404) {
            this.loading = false;
            this.validationerror = "Email or Password is incorrect !";
            setTimeout(() => {
              this.validationerror = "";
            }, 6000);
          }
          else if (error.status == 403) {
            this.loading = false;
            this.resendtext = true;
            this.resendlink.email = this.login.email;
            this.validationerror = "Your email is not verified. We have sent you a verification email. Please click on the link there to verify your email address.";
            setTimeout(() => {
              this.validationerror = "";
            }, 12000);
          }
          else if (error.status == 402) {
            this.loading = false;
            this.validationerror = "Your account is inactive/deleted. Please get in touch with admin for more information.";
            setTimeout(() => {
              this.validationerror = "";
            }, 12000);
          }
        });
  }

  public ResendLink() {
    this.router.navigate(["/ResendLink"]);
  }

  showTermsAndConditions() {
    const ref = this.dialogService.open(TermsAndConditionsComponent, {
      header: 'Terms & Conditions',
      width: '70%',
      height: '70%'
    });
  }

}


