import { Component, Input, OnInit } from '@angular/core';
import { MessagingService } from '../../../../../../services/messaging.service';
import { AddressResultModel } from '../../../../../models/AddressResultModel';
import { SearchService } from 'src/app/demo/V3/services/search.service';
@Component({
  selector: 'app-address-result-personal',
  templateUrl: './address-result.component.html',
  styleUrls: ['./address-result.component.css', '../../../../shared/shared.searchpage.css']
})
export class AddressResultPersonalComponent implements OnInit {

  @Input() public address: AddressResultModel = new AddressResultModel();
  public APIKey: string;

  constructor(private messageService: MessagingService,
              private searchService: SearchService) { }

  ngOnInit(): void {
 }

  public getDataSourcesString(sources: any): string {
    if (!sources || sources.length == 0)
      return "";

    let sourceArr = sources.filter(x => x.Name);
    if (sourceArr.length == 0)
      return "";

    return sourceArr.map(x => x.Name).join(", ");
  }

  streetViewUrl(address: any) {
    
    const addressLines = [
      address.AddressLine1,
      address.AddressLine2,
      address.AddressLine3,
      address.AddressLine4,
      address.AddressLine5,
      address.AddressLine6,
      address.Country,
      address.Postcode
    ];
    const fullAddress = addressLines.filter(line => line?.trim()).join(", ");
    const encodedAddress = encodeURIComponent(fullAddress);
    return `https://maps.googleapis.com/maps/api/streetview?size=600x300&location==${encodedAddress}&key=AIzaSyD6bQM-KV4Ul5Fcycmlzu7q4gWGJ05lraE`;
  }
}
