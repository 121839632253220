<table class="table table-striped table-hover">
  <tbody>
    <tr>
      <th scope="row">Company Name</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.companySummary?.businessName}}</td>
    </tr>
    <tr>
      <th scope="row">Company Number</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.companySummary?.companyRegistrationNumber}}</td>
    </tr>
    <tr>
      <th scope="row">Incorporation Date</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.companyIdentification?.basicInformation?.companyRegistrationDate | date:'dd-MMM-yyyy'}}</td>
    </tr>
    <tr>
      <th scope="row">Status</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.companySummary?.companyStatus?.status}}</td>
    </tr>
    <tr>
      <th scope="row">Type</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.companyIdentification?.basicInformation?.legalForm?.description}}</td>
    </tr>
    <tr>
      <th scope="row">SIC Code</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.companySummary?.mainActivity?.code}}</td>
    </tr>
    <tr>
      <th scope="row">Description</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.companySummary?.mainActivity?.description}}</td>
    </tr>
    <tr>
      <th scope="row">Address Type</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.contactInformation?.mainAddress?.type}}</td>
    </tr>
    <tr>
      <th scope="row">Address</th>
      <td><a [href]="streetViewUrl(business?.responseData?.creditsafeResult?.report?.companyIdentification?.basicInformation?.contactAddress?.simpleValue)" target="_blank">{{business?.responseData?.creditsafeResult?.report?.companyIdentification?.basicInformation?.contactAddress?.simpleValue}}</a></td>
    </tr>
    <tr>
      <th>Other Addresses</th>
      <td>
        <table class="table table-striped table-hover" *ngIf="business?.responseData?.creditsafeResult?.report?.contactInformation?.otherAddresses && business?.responseData?.creditsafeResult?.report?.contactInformation?.otherAddresses?.length > 0">
          <tr *ngFor="let address of business?.responseData?.creditsafeResult?.report?.contactInformation?.otherAddresses">
            <td><a [href]="streetViewUrl(address.simpleValue)" target="_blank">{{address.simpleValue}}</a></td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <th scope="row">Phone Number</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.contactInformation?.mainAddress?.telephone}}</td>
    </tr>
    <tr>
      <th scope="row">Website</th>
      <td>
        <div *ngFor="let website of business?.responseData?.creditsafeResult?.report?.contactInformation?.websites"><a class="link">{{website}}</a></div>
      </td>
    </tr>
    <tr>
      <th scope="row">VAT Number</th>
      <td>{{business?.responseData?.creditsafeResult?.report?.companyIdentification?.basicInformation?.vatRegistrationNumber}}</td>
    </tr>
    <tr>
      <th scope="row">Previous Name Details</th>
      <td>
        <table class="table table-striped table-hover" *ngIf="business?.responseData?.creditsafeResult?.report?.companyIdentification?.previousNames?.length > 0">
          <thead>
            <tr>
              <th>Previous Name</th>
              <th>Date Previous Name Changed</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let previousName of business?.responseData?.creditsafeResult?.report?.companyIdentification?.previousNames">
              <td>{{previousName?.name}}</td>
              <td>{{previousName?.dateChanged | date:'dd-MMM-yyyy'}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <th scope="row">Subsidiary Companies</th>
      <td>
        <table class="table table-striped table-hover" *ngIf="business?.responseData?.creditsafeResult?.report?.groupStructure?.subsidiaryCompanies?.length > 0">
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Company Number</th>
              <th>Type</th>
              <th>Status</th>
              <th>Address</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let company of business?.responseData?.creditsafeResult?.report?.groupStructure?.subsidiaryCompanies">
              <td>{{company?.name}}</td>
              <td>{{company?.registrationNumber}}</td>
              <td>{{company?.type}}</td>
              <td>{{company?.status}}</td>
              <td>{{company?.address?.simpleValue}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
    <tr>
      <th scope="row">Employee Information</th>
      <td>
        <table class="table table-striped table-hover" *ngIf="business?.responseData?.creditsafeResult?.report?.otherInformation?.employeesInformation?.length > 0">
          <thead>
            <tr>
              <th>Year</th>
              <th>Number Of Employees</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let employee of business?.responseData?.creditsafeResult?.report?.otherInformation?.employeesInformation">
              <td>{{employee?.year}}</td>
              <td>{{employee?.numberOfEmployees}}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>
