<section id="personal-search" class="search">
    <div class="container">
      <div class="logo-div d-flex justify-content-center">
        <img class="logo" src="assets/Images/new/HUT-icon.v3-personal-RO.png" />
      </div>
      <form role="form" [formGroup]="personalForm">
        <div class="form-group row form-top-margin">
          <div class="offset-md-2 col-md-8 form-data-background form-borderdesign  shadow-lg p-3" formGroupName="profile">
            <div class="row padding-form-design">
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Reference:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="reference" autocomplete="off" />
                        <p class="text-danger" *ngIf="personalForm.get('profile.reference').errors?.required">
                          <strong><small>{{messagingService.ReferenceRequiredMessage}}</small></strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Name:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="name" autocomplete="off" />
                        <p class="text-danger" *ngIf="personalForm.get('profile.name').errors?.required">
                          <strong><small>{{messagingService.NameRequiredMessage}}</small></strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>DOB:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="date" placeholder="" class="form-control datepicker" formControlName="dob" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Phone:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="phone" autocomplete="off" />
                        <p class="text-danger" *ngIf="personalForm.get('profile.phone').errors?.pattern">
                          <strong><small>{{messagingService.PhoneNumberNotValidMessage}}</small></strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Email:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="email" placeholder="" class="form-control" formControlName="email" autocomplete="off" />
                        <p class="text-danger" *ngIf="personalForm.get('profile.email').errors?.pattern">
                          <strong><small>{{messagingService.EmailNotValidMessage}}</small></strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Street Address:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="streetaddress" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Suburb:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="suburb" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>State:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="state" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 z-index-formdata">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-3 hut-form-label d-flex justify-content-end mt-4">
                      <b>Post Code:</b>
                    </div>
                    <div class="col-md-8 pt-3">
                      <div class="form-group">
                        <input type="text" placeholder="" class="form-control" formControlName="postcode" autocomplete="off" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 padding-form-design pb-2">
                <div class="row">
                  <div class="input-group">
                    <div class="col-md-12 offset-md-3">
                      <div class="form-group">
                        <button type="submit" class="btn colorRed mx-2"
                            (click)="personalSearch()">
                          Search <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
  
                        <button type="button" class="btn colorRed btn-pdf mx-2"
                            *ngIf="saveAsPDFButtonVisibility">
                          Save as PDF
                        </button>
  
                        <button class="btn colorRed reset-btn mx-2"
                            *ngIf="clearSearchButtonVisibility"
                            (click)="personalClearSearch()">
                          Clear Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  
    <div>
      <div class="container" *ngIf="showResultTab">
        <div class="mb-5">
          <div class="background-white result-display-padding form-borderdesign result-view-align" style="border: 1px solid rgba(0,0,0,.125);">

            <ul class="nav nav-tabs" id="searchResultTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="personhistory-search-tab" data-toggle="tab" href="#personHistorySearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Person History</b></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="enhancedrecordplus-search-tab" data-toggle="tab" href="#enhancedRecordPlusSearch" role="tab"
                   aria-controls="profile" aria-selected="false"><b>Enhanced Record Plus</b></a>
              </li>
            </ul>
  
            <div class="tab-content" id="searchResultTabContent">
              <br />
    
              <!-- Person History Results -->
              <div class="tab-pane fade show tab-height active" id="personHistorySearch" role="tabpanel" aria-labelledby="personhistory-search-tab">
                <div>
                  <div class="col-md-12" *ngIf="!personHistoryResultModel?.isLoading && personHistoryResultModel.validation && !personHistoryResultModel.searchFail"><i class="color-grey float-right">{{messagingService.OtherFieldRequired}}</i></div>
                  <div class="col-md-12" *ngIf="!personHistoryResultModel?.isLoading && !personHistoryResultModel.validation && personHistoryResultModel.searchFail"><i class="color-grey float-right">{{messagingService.SearchFail}}</i></div>
                  <div class="col-md-12" *ngIf="!personHistoryResultModel?.isLoading && !personHistoryResultModel.validation && !personHistoryResultModel.searchFail && (!personHistoryResultModel?.responseData?.data)"><i class="color-grey float-right">{{messagingService.NoRelevantDataMessage}}</i></div>
                </div>
                <div>
                  <span class="loadingImg" *ngIf="personHistoryResultModel?.isLoading">
                    <img src="{{messagingService.loadingAnimation}}" />
                  </span>
                </div>
                <div *ngIf="!personHistoryResultModel?.isLoading && !personHistoryResultModel.validation && !personHistoryResultModel.searchFail && personHistoryResultModel?.responseData && personHistoryResultModel?.responseData?.data">
                  <pre>{{personHistoryResultModel?.responseData?.data | json}}</pre>
                </div>
              </div>

              <!-- Enhanced Record Plus Results -->
              <div class="tab-pane fade show tab-height" id="enhancedRecordPlusSearch" role="tabpanel" aria-labelledby="enhancedrecordplus-search-tab">
                <div>
                  <div class="col-md-12" *ngIf="!enhancedRecordPlanResultModel?.isLoading && enhancedRecordPlanResultModel.validation && !enhancedRecordPlanResultModel.searchFail"><i class="color-grey float-right">{{messagingService.OtherFieldRequired}}</i></div>
                  <div class="col-md-12" *ngIf="!enhancedRecordPlanResultModel?.isLoading && !enhancedRecordPlanResultModel.validation && enhancedRecordPlanResultModel.searchFail"><i class="color-grey float-right">{{messagingService.SearchFail}}</i></div>
                  <div class="col-md-12" *ngIf="!enhancedRecordPlanResultModel?.isLoading && !enhancedRecordPlanResultModel.validation && !enhancedRecordPlanResultModel.searchFail && (!enhancedRecordPlanResultModel?.responseData?.data)"><i class="color-grey float-right">{{messagingService.NoRelevantDataMessage}}</i></div>
                </div>
                <div>
                  <span class="loadingImg" *ngIf="enhancedRecordPlanResultModel?.isLoading">
                    <img src="{{messagingService.loadingAnimation}}" />
                  </span>
                </div>
                <div *ngIf="!enhancedRecordPlanResultModel?.isLoading && !enhancedRecordPlanResultModel.validation && !enhancedRecordPlanResultModel.searchFail && enhancedRecordPlanResultModel?.responseData && enhancedRecordPlanResultModel?.responseData?.data">
                  <pre>{{enhancedRecordPlanResultModel?.responseData?.data | json}}</pre>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="pt-1"></div>
      </div>
    </div>
  </section>
  
  <div style="position: absolute; top: 0; right: 120%;">
    <kendo-pdf-export #pdf>
      <app-export-pdf-personal-au></app-export-pdf-personal-au>
    </kendo-pdf-export>
  </div>
  