<div class="app-outer-container page-background">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
        </div>
        <div class="col-md-6">
          <div class="card card-body card-radius">
            <h2 class="headingalign text-center">OTP verification</h2>
            <div class="text-center">
              <p>check your e-mail for one-time passcode</p>
              <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{ length: 6, allowNumbersOnly: true }" (keydown)="onKeydown($event)"></ng-otp-input>
            </div>
            <div class="text-center mt-4">
              <p *ngIf="errorMessage != null" class="text-danger">{{ errorMessage }}</p>
              <p *ngIf="isVisibleTimer">Time Remaining: {{ timerMinutes }}:{{ timerSeconds | number: '2.0' }}</p>
              <button
                class="btn btn-link"
                (click)="resendOtp()"
                [disabled]="!canResend"
              >
                Resend OTP
              </button>
            </div>
            <div class="text-center mt-4">
            <button class="btn red-grad-button btn-block" [disabled]="!isVisibleVerify" (click)="verifyOtp()">Verify</button>
           
          </div>
          </div>
        </div>
        <div class="col-md-3">
          
        </div>
      </div>
    </div>
  </div>
  