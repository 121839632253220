import { Feedback } from './../models/Feedback';
import { HtmltoPdf } from './../models/HtmltoPdf';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Token } from '../../shared/models/token';
import { Items } from '../models/Items';
import { SearchDomain } from '../models/SearchDomain';
import { ContactData } from '../models/ContactData';
//import { RequestData } from '../models/AccessData';
import { AVAResultData } from '../models/AVAModel/AVAResultData';
import { AccessData, RequestData, SecondAccess, Item } from '../models/AccessData';



@Injectable({
  providedIn: 'root'
})
export class SearchService {

  public GetPersonSearch(requestData: RequestData, searchtext: string, auditKey: string): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/GetPersonSearch?searchText=' + searchtext + '&auditKey=' + auditKey, requestData, httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;
          return body
            || {};
        })
      );
  }

  constructor(private http: HttpClient) { }

  public getDomainJson(): Observable<SearchDomain[]> {
    return this.http.get<SearchDomain[]>('../assets/jsonfiles/searchDomains.json');
    // .pipe(map((res: Response) => { let body = res;return body| {}; }));
  }
  //public auditSearchData(userId: any, accessData: any, inputdata: any) {
  //  const reqeustData = {
  //    "id": userId,
  //    "input": inputdata,
  //    "access": accessData
  //  }
  //  return this.http.post<object>('/api/Search/AuditSearch', reqeustData).toPromise().then((data) => {
  //    console.log(data);
  //  });
  //}

  public SearchData(search: string, lexicon: string, userId: string, searchDomain: string, auditKey: string, requestData: RequestData): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/DataSearch?search=' + search + '&lexiconKey=' + lexicon + '&userId=' + userId + '&searchDomain=' + searchDomain + '&auditKey=' + auditKey, requestData, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body
    //      || {};
    //  })
    //);
  }

  public GetCounterFraudData(requestData: RequestData, searchtext: string, auditKey: string, userId: string): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/GetCounterFraudData?searchText=' + searchtext + '&auditKey=' + auditKey + '&userId=' + userId, requestData, httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;
          return body
            || {};
        })
      );
  }

  public GetProspectHitData(requestData: RequestData, searchtext: string, auditKey: string, userId: string): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/GetProspectHitData?searchText=' + searchtext + '&auditKey=' + auditKey + '&userId=' + userId, requestData, httpOptions);
  }

  public GetProspectProfileData(requestData: RequestData, searchtext: string, auditKey: string, userId: string): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/GetProspectProfileData?searchText=' + searchtext + '&auditKey=' + auditKey + '&userId=' + userId, requestData, httpOptions);
  }

  public RightToWorkSearch(requestData: RequestData, searchtext: string, auditKey: string): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/RightToWork?searchName=' + searchtext + '&auditKey=' + auditKey, requestData, httpOptions);
  }

  public IDCheck(requestData: RequestData, searchtext: string, auditKey: string): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/IDCheck?searchName=' + searchtext + '&auditKey=' + auditKey, requestData, httpOptions);
  }

  public USIDCheck(name: string, email: string, reference: string, formData: FormData): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/USIDCheck?searchName=' + name + '&email=' + email + '&reference=' + reference, formData, httpOptions);
  }

  public EmailAge(requestData: RequestData, searchtext: string, auditKey: string): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/EmailAge?searchName=' + searchtext + '&auditKey=' + auditKey, requestData, httpOptions);
  }

  public CheckProsectHitAndProfileAccessClient(): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get<Object>('/api/Search/CheckProspectClientData', httpOptions);
  }

  public GetMobileData(reqeustData: RequestData, searchtext: string, auditKey: string, userId: any): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/GetMobileData?searchText=' + searchtext + '&auditKey=' + auditKey + '&userId=' + userId, reqeustData, httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;
          return body
            || {};
        })
      );
  }

  public DirectorshipSearchData(requestData: RequestData, search: string, auditKey: string, userId: any): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/DirectorshipSearch?searchText=' + search + '&auditKey=' + auditKey + '&userId=' + userId, requestData, httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;
          return body
            || {};
        })
      );
  }

  public analyseResultRanking(searchUniqueIdlist: string[], lexiconCategory: string, searchvalue: string, searchDomain: string, devData: boolean, auditKey: string, requestData: RequestData, userId: string): Observable<Items[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    const allRequestData = {
      "searchUniqueIdlist": searchUniqueIdlist,
      "requestData": requestData
    }
    return this.http.post<Items[]>('/api/Search/AnalyseResultRanking?category=' + lexiconCategory + '&searchvalue=' + searchvalue + '&searchDomain=' + searchDomain + '&devData=' + devData + '&auditKey=' + auditKey + '&userId=' + userId, allRequestData, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body
    //      || {};
    //  }));
  }

  public refreshtoken(token: Token): Observable<Token> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<Token>('/api/Token/Refresh', token, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body
    //      || {};
    //  }));
  }

  public logout(userId:string){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post<any>('/api/Account/Logout?userId=' + userId,httpOptions);
  }

  public AssociatedSearchData(search: string, searchDomain: string, requestData: RequestData) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<Items[]>('/api/Search/AssociatedDataSearch?search=' + search + '&searchDomain=' + searchDomain, requestData, httpOptions);
  }


  public AuditUserSearchResults(searchvalue: string, userId: string): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<string>('/api/Search/AuditUserSearchResults?search=' + searchvalue + '&userId=' + userId, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body
    //      || {};
    //  }));
  }

  public FollowSearchKeyword(searchvalue: string, userId: string, followStatus: boolean): Observable<string> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<string>('/api/Search/AddFollowSearch?search=' + searchvalue + '&userId=' + userId + '&followStatus=' + followStatus, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body
    //      || {};
    //  }));
  }

  public CheckFollowing(searchvalue: string, userId: string): Observable<boolean> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<boolean>('/api/Search/CheckFollowing?search=' + searchvalue + '&userId=' + userId, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body;
    //  }));
  }


  public SearchLifeStyle(search: string, EachlifestyleCategory: string, searchDomain: string, userId: string, auditKey: string, requestData: RequestData): Observable<Items[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}`, 'Retry-After': '180' })
    };
    return this.http.post<Items[]>('/api/Search/SearchLifeStyleData?search=' + search + '&EachlifestyleCategory=' + EachlifestyleCategory + '&searchDomain=' + searchDomain + '&userId=' + userId + '&auditKey=' + auditKey, requestData, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body;
    //  }));
  }

  public AnalysisSports(searchUniqueIdlist: string[], userId: string, auditKey: string, requestData: RequestData, searchName: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}`, 'Retry-After': '180' })
    };
    const allRequestData = {
      "searchUniqueIdlist": searchUniqueIdlist,
      "requestData": requestData
    }
    return this.http.post<Items[]>('/api/Search/AnalyseSportsResultRanking?searchName=' + searchName + '&userId=' + userId + '&auditKey=' + auditKey, allRequestData, httpOptions)
  }


  public SearchSocialMedia(search: string, EachsocialMediaCategory: string, searchDomain: string, userId: string, auditKey: string, reqeustData: RequestData): Observable<Items[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<Items[]>('/api/Search/SearchSocialMediaData?search=' + search + '&EachsocialMediaCategory=' + EachsocialMediaCategory + '&searchDomain=' + searchDomain + '&userId=' + userId + '&auditKey=' + auditKey, reqeustData, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body;
    //  }));
  }

  public AnalysisSocialMedia(searchUniqueIdlist: string[], EachsocialMediaCategory: string, phone: string, userId: string, auditKey: string, requestData: RequestData, searchName: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    const allRequestData = {
      "searchUniqueIdlist": searchUniqueIdlist,
      "requestData": requestData
    }
    return this.http.post<Items[]>('/api/Search/AnalyseSocialMediaResultRanking?searchName=' + searchName + '&userId=' + userId + '&auditKey=' + auditKey + '&phone=' + phone + '&EachsocialMediaCategory=' + EachsocialMediaCategory, allRequestData, httpOptions);
  }

  public SearchAdvert(phone: string, userId: string, auditKey: string, requestData: RequestData, searchName: string): Observable<Items[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<Items[]>('/api/Search/SearchAdvert?searchName=' + searchName + '&userId=' + userId + '&auditKey=' + auditKey + '&phone=' + phone, requestData, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body;
    //  }));
  }

  public AnalysisAdvert(searchUniqueIdlist: string[], phone: string, userId: string, auditKey: string, requestData: RequestData, searchName: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    const allRequestData = {
      "searchUniqueIdlist": searchUniqueIdlist,
      "requestData": requestData
    }
    return this.http.post<Items[]>('/api/Search/AnalyseAdvertResultRanking?searchName=' + searchName + '&userId=' + userId + '&auditKey=' + auditKey + '&phone=' + phone, allRequestData, httpOptions);
  }

  public EmailDomainCheck(name: string, userId: string, auditKey: string, reqeustData: RequestData): Observable<object> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<object>('/api/Search/EmailDomainCheck?searchName=' + name + '&userId=' + userId + '&auditKey=' + auditKey, reqeustData, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body;
    //  }));
  }

  public GetToggleSettings(userId: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get('/api/Search/GetToggleSettings?userId=' + userId, httpOptions)
      .pipe(
        map((res: Response) => {
          let body = res;
          return body;
        }));
  }

  public updateAudit(auditKey: string, api: number, data: string[]) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post('/api/Search/UpdateAuditByAPI?auditKey=' + auditKey + '&api=' + api, data, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body;
    //  }));
  }

  public SearchAVA(name: string, userId: string, auditKey: string, requestData: RequestData): Observable<AVAResultData> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<AVAResultData>('/api/Search/SearchAVAData?auditKey=' + auditKey + '&userId=' + userId + "&searchName=" + name, requestData, httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body;
    //  }));
  }

  public SearchAML(name: string, auditKey: string, requestData: RequestData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post<any>('/api/Search/SearchAMLData?auditKey=' + auditKey + "&searchName=" + name, requestData, httpOptions);
  }

  public SearchCCJAndInsolvency(name: string, auditKey: string, requestData: RequestData, finalcialData: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    const request = {
      "finalcialData": finalcialData,
      "requestData": requestData
    }
    return this.http.post<any>('/api/Search/SearchCCJInsolvencyDetailData?auditKey=' + auditKey + "&searchName=" + name, request, httpOptions);
  }

  AllowedLicence(): Observable<Item[]> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get<Item[]>('api/search/GetLicenceItem', httpOptions);
    //.pipe(
    //  map((res: Response) => {
    //    let body = res;
    //    return body;
    //  }));
  }

  GetAuditKey(): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get('api/search/GetAuditKey', httpOptions).toPromise();
  }

  GetSearch4Less(croNumber: string, companyName: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get('api/search/Search4Less?companyName=' + companyName + "&croNumber=" + croNumber, httpOptions);
  }


  GetPhronesisBiometric(mobileNumber: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.get('api/search/PhronesisBiometric?mobileNumber=' + mobileNumber, httpOptions);
  }

  GetHtmltoPdf(htmlElement: HtmltoPdf) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post('/api/Search/PdfSharpConvert', htmlElement, httpOptions);
  }

  FeedbackReport(report: Feedback) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem("access_token")}` })
    };
    return this.http.post('api/Search/ResultFeedBack', report, httpOptions);
  }
  
}

